
if(document.getElementById('bm')) {
    var animation = bodymovin.loadAnimation({
        container: document.getElementById('bm'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: 'data.json',
    });
    animation.onComplete = function() {
        window.readyToLoad2 = true;
        readyToLoad();
    }
}

$(function(){
    if($(".loaderscreen").length > 0){
        doStuffOnHomePageLoad();
    }else if($('.page--home').get(0) && show_loading == 0){
        doStuffOnHomePageLoad(true);
    }else{
        afterAnimStuffLoad();
    }
});


function enableScroll() { 
    $('body').css({"overflow" : "auto"});
    window.onscroll = function() {}; 
}

function doStuffOnHomePageLoad(home2ndTime){
    var initDelay = 0;
    if(home2ndTime) {
        initDelay = 900;
    }else{
        window.initLoadingAnimDone = true;
    }
    afterAnimStuffLoad();
    setTimeout(function(){
        $('.header .row').hide();
    }, initDelay);
    setTimeout(function(){
        $('.home-banner .row .home-banner-text h1').fadeIn();
    }, initDelay + 1300);
    setTimeout(function(){
        $('.home-banner .row .home-banner-text p').fadeIn();
    }, initDelay + 1900);
    setTimeout(function(){
        $('.home-banner .row .home-banner-btn').show();
    }, initDelay + 2300);

    var waitTime = 2000;
    if(home2ndTime) {
        // waitTime = 1000;

        setTimeout(function(){
            readyToLoad(true, initDelay);
        }, waitTime);
    }else{
        window.readyToLoad1 = true;
        readyToLoad();
    }
}

function animateOnScrollInner() {
    $('.animate').on('inview', function(event, isInView) {
        var thiss = $(this);
        var animationName = $(thiss).attr("data-animate");
        var animationDelay = $(thiss).attr("data-animate-delay");
        if(animationDelay > 100) {
            animationDelay = 100;
        }
        if (isInView) {
            setTimeout(function(){
                $(thiss).addClass(animationName);
                $(thiss).addClass('animated');
            }, animationDelay);
        } else {
            // $(thiss).removeClass(animationName);
            // $(thiss).removeClass('animated');
        }
    });
}

function animateOnScroll() {
    if(window.initLoadingAnimDone === false) {
        setTimeout(function(){
            animateOnScroll();
        }, 200);
    }else{
        if(typeof(show_loading) == 'number' && show_loading == 1) {
            if(window.readyToLoad1 && window.readyToLoad2) {
                animateOnScrollInner();
            }else{
                setTimeout(function(){
                    animateOnScroll();
                }, 200);
            }
        }else{
            animateOnScrollInner();
        }
    }
}

function readyToLoad(loadNow, initDelay) {
    if(!initDelay) {
        var initDelay = 0;
    }
    if(window.readyToLoad1 && window.readyToLoad2 || loadNow) {
        window.mainVideoPlayForce = setInterval(function(){
            // var thisVideo = $('.video-banner video')[0];
            if(typeof Wistia === 'undefined' || Wistia === null){
            }else{
                clearInterval(window.mainVideoPlayForce);
                if(window.innerWidth < 768) {
                    Wistia.api('i65l8ilt29').play();
                }else{
                    Wistia.api('o57yqjjeab').play();
                }
                // if(thisVideo.currentTime > 0.1) {
                $('.after-loading').addClass('hide-layer');
                $('.loaderscreen').fadeOut(400);
                enableScroll();
                setTimeout(function(){
                    $('.header .row').show();
                }, initDelay);
            }
            // }else{
                // thisVideo.play();
            // }
        }, 200);
    }
}

var waitingForAfterAnimStuff;
var doOnLoadFunctions = false;
function afterAnimStuffLoad() {
    if(doOnLoadFunctions === false) {
        animateOnScroll();
        ChangeToSvg();
        doOnLoadFunctions = true;
    }
    if(waitingForAfterAnimStuff) { return; }
    waitingForAfterAnimStuff = setInterval(function(){
        if(typeof afterAnimStuff === 'undefined'){
        }else{
            clearInterval(waitingForAfterAnimStuff);
            afterAnimStuff();
        }
    }, 300);
}

function ChangeToSvg() {
    $('img.js-tosvg').each(function() {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        $.get(imgURL, function(data) {
            var $svg = $(data).find('svg');

            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }

            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' insvg');
            }

            $svg = $svg.removeAttr('xmlns:a');

            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            $img.replaceWith($svg);
        }, 'xml');
    });
}